import { Routes } from '@angular/router';
import { RegisterComponent } from './authentification/register/register.component';
import { LoginComponent } from './authentification/login/login.component';
import { ClientsComponent } from './clients/clients.component';
import { ClientComponent } from './client/client.component';
import { LogoutComponent } from './authentification/logout/logout.component';
import { ShellComponent } from './shell/shell.component';
import { SupportComponent } from './support/support.component';
import { AboutComponent } from './about/about.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CabinetComponent } from './cabinet/cabinet.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { OrderComponent } from './order/order.component';
import { ShellLoginComponent } from './shell-login/shell-login.component';
import { loginGuard } from './guards/login.guard';
import { orderGuard } from './guards/order.guard';
import { authGuard } from './guards/auth.guard';
import { rightsGuard } from './guards/rights.guard';
import { PersonalCabinetComponent } from './personal-cabinet/personal-cabinet.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { CreateOrderLamellaComponent } from './create-order-lamella/create-order-lamella.component';

export const routes: Routes = [
  { path: 'register', component: RegisterComponent, title: 'Регистрация'},
  { path: 'login', component: LoginComponent, title: 'Вход', canActivate: [authGuard] },
  { path: 'shell', component: ShellComponent },
  { path: 'shell-login', component: ShellLoginComponent },
  { path: 'logout', component: LogoutComponent, title: 'Выход', canActivate: [orderGuard] },
  { path: 'clients', component: ClientsComponent, title: 'Клиенты', canActivate: [loginGuard, orderGuard, rightsGuard] },
  { path: 'client/:id', component: ClientComponent, title: 'Клиент', canActivate: [loginGuard, orderGuard, rightsGuard] },
  { path: 'support', component: SupportComponent, title: 'Техподдержка', canActivate: [orderGuard]},
  { path: 'about', component: AboutComponent, title: 'О компании', canActivate: [orderGuard] },
  { path: 'pd', component: PersonalDataComponent, title: 'Согласие ПД', canActivate: [orderGuard] },
  { path: 'list', component: OrdersListComponent, title: 'Список заказов', canActivate: [loginGuard, orderGuard] },
  { path: 'order/:id', component: OrderComponent, title: 'Заказ', canActivate: [loginGuard, orderGuard] },
  { path: 'cabinet', component: CabinetComponent, title: 'Кабинет', canActivate: [loginGuard, orderGuard, rightsGuard] },
  { path: 'lk', component: PersonalCabinetComponent, title: 'Личный кабинет', canActivate: [loginGuard, orderGuard] },
  { path: 'create-lamella', component: CreateOrderLamellaComponent, title: 'Конструктор реек', canActivate: [loginGuard, orderGuard] },
  { path: 'create', component: CreateOrderComponent, title: 'Конструктор фасадов', canActivate: [loginGuard, orderGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent, title: '404' },
];
