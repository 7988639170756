<div class="page">
  <div style="padding-top: 10px;">
    <div class="video" >
      <video class="first-video" controls style="width: 65vw;">
        <source src="https://storage.yandexcloud.net/fabfas/glav_video.mp4" type="video/mp4">
        Браузер не поддерживает это видео.
      </video>
    </div>
  <div style="width: 70vw;">
      <div style="margin-bottom: 60px;">
        <p>
          Первая Фабрика Фасадов - единственный в РФ и СНГ производитель мебельных фасадов, который применяет автоматизированную систему изготовления фасадов с применением PUR технологии (нанесение полиуретанового клея на все поверхности фасадов)
        </p>
        <p>
          Применяются только качественные материалы и сырье. Изготовление фасадов происходит на европейском автоматизированном оборудовании.
        </p>
      </div>
      <p class="bold">Уважаемые клиенты и партнеры!</p>
      <p>
        <span class="bold">С 1 сентября 2024</span> года прием заявок будет осуществляться только через данный портал
      </p>
  
  
      <p class="bold-underline">Почему это удобно для вас?</p>
  
      <p>
        <span class="bold">Упрощение процесса </span><br>
        Вам больше не нужно заполнять бланки заказов в Excel и отправлять их нам на почту
      </p>
  
      <p>
        <span class="bold">Актуальная информация</span> <br>
        При оформлении заявки будут доступны только те фрезеровки и декоры, которые есть в наличии на момент заказа.
        Это исключает необходимость пересогласования с заказчиком из-за отсутствия материалов
      </p>
  
      <p>
        <span class="bold">Актуальные цены</span> <br>
        Вам не нужно запрашивать прайсы, так как цены при расчете всегда будут актуальными
      </p>
  
      <p>
        <span class="bold">Розничные цены</span> <br>
        Вы сможете рассчитать заказ в розничных рекомендованных ценах (РРЦ)
      </p>
  
      <p>
        <span class="bold">Оптовые цены</span> <br>
        Постоянным оптовым клиентам будет отправлен счет с учетом вашей индивидуальной скидки и выбранной вами формы оплаты.
        Также вы сможете увидеть сумму в "Журнале заказов"
      </p>
  
      <p>
        <span class="bold">Отслеживание статуса</span> <br>
        Вы сможете отслеживать статус своих заказов в "Журнале заказов"
      </p>
  
      <p>
        <span class="bold">Минимизация ошибок</span> <br>
        Исключаются ошибки при расчете, так как иногда заявки клиентов трудно разобрать.
        Самостоятельный ввод данных для производства и выставление счета исключает изготовление неправильных размеров или декоров
      </p>
  
      <p  class="italic">Мы уверены, что новый способ оформления заявок сделает процесс более удобным и эффективным для всех наших клиентов и партнеров.</p>
  
    </div>
  </div>
</div>
