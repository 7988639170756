<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
</head>
<div class="white-page">
  @if(errorMessage){<p class="error-message">{{ errorMessage }}</p>}
    @if(loading){
      <h3>Загрузка</h3>
    } @else{
      <div class="buttons-top">
      @if(!parentCheck){
        @if(roleCheck){
          <div class="button-bottom">
            <button class="button delete-button" (click)="deleteOrder()"> Удалить заказ </button>
          </div>
        } @else{
          @if(order.status == 'Сохранён' || order.status == 'Ожидает оплаты' || order.status == 'В обработке у менеджера'){
            <div class="button-bottom">
              <button class="button delete-button" (click)="cancelOrder()"> Отменить заказ </button>
              <span class="green" style="background-color: white; font-size: large;"> {{ cancelMessage }} </span>
            </div>
          }
        }
        @if(order.status == 'Сохранён'){
          <button class="button push-button" (click)="sendOrder()"> Получить счёт </button>
          <div class="payment">
            <div>
              <label class="label-payment" for="paymentPlan">Планируемая форма оплаты</label>
              <select class='text-design payment-select' id="paymentPlan" [(ngModel)]="paymentPlanForm">
                @for (opt of ['Наличные, как физическое лицо','Картой физического лица','Перевод на счёт с карты физического лица','Расчётный счёт от юридического лица']; track $index) {
                  <option> {{ opt }} </option>
                }
                <option selected disabled value="">----</option>
              </select>
            </div>
          </div>
          <div class="payment payment-date">
            <div>
              <label class="label-payment" for="paymentDate">Планируемая дата оплаты</label>
              <input class='input-payment' [ngModel]="paymentDate | date:'yyyy-MM-dd'"
              (ngModelChange)="paymentDate = $event"
              type="date" id="paymentDate" class="input-date" value="{{paymentDate}}" min="2024-17-07" max="2025-12-31" />
            </div>
          </div>
        }
        @if(roleCheck){
          <button class="button export-button" (click)="exportToCSV()"> Скачать файл</button>
          <input class='delimiter' type="checkbox" id="delimiter" name="delimiter" [(ngModel)]="delimiter"/>
          <label class='delimiter'  for="delimiter">Сменить разделитель (excel)</label>
        }
      }
      <div class="button-bottom">
        <button class="button export-button" (click)="copyOrder()"> Скопировать заказ </button>
      </div>
    </div>
      <div class="border-radius">
        <table class="main-info">
          <thead>
            <tr>
              <th>Создан</th>
              <th>Дата оплаты</th>
                <th>План-дата поступления на склад</th>
                <th>Факт-дата поступления на склад</th>
                <th>Номер</th>
                <th>Статус</th>
                <th>Сумма РРЦ</th>
              @if(personCheck){
                @if(markupCheck){
                  <th>Сумма РРЦ с %</th>
                }
                <th>Оптовая сумма</th>
              }
              @if(roleCheck){
                <th>Лицо</th>
                <th>ФИО</th>
              }
              <th>S<sub>общая</sub></th>
              <th>Ответственный</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ order.created | dateColhoz }}</td>
              <td>
                @if(order.paymentStatus === 'Ждёт оплаты'){
                  ----
                } @else {
                  {{ order.paymentStatus }}
                }
              </td>
              <td>{{ order.dateWhenWarehouse }}
                  @if(!order.dateWhenWarehouse)
                    { ---- }
              </td>
              <td>
              @if(!order.dateWhenShipping) {
                   ----
                } @else{
                  {{ order.dateWhenShipping}}
                }

              </td>
              <td>
                <a href="/order/{{ order._id }}" class="number-text">
                  @if(!order.customID)
                    { Номер }
                  @else {
                    {{ order.customID }}
                  }
                </a>
                <p class="type">
                  @if(order.kind){
                    {{ TYPE_TO_RUS[order.kind] }}
                  }
                </p>
              </td>
              <td [ngClass]="{'red': order.status === 'Ждёт оплаты','yellow': order.status === 'Изготавливается', 'green': order.status ==='На складе'}"> {{ order.status }} </td>
              <td> <span style="text-wrap: nowrap;">{{ order.totalPrice | number }} </span></td>
              @if(personCheck){
                @if(markupCheck){
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup | number }} </span></td>
                }
                <td>
                  @if(!order.totalPriceDiscount){
                    ----
                  }
                  <span style="text-wrap: nowrap;">{{ order.totalPriceDiscount | number }} </span>
                </td>
              }
              @if(roleCheck){
                <td>{{ order.person }}</td>
                <td [ngClass]="{'green-bg': order.createdResponsible === name}">
                  {{ order.name }}<ng-container *ngIf="order.createdResponsible" >
                    <span  
                       matTooltip="{{ order.createdResponsible }}" 
                       matTooltipPosition="above">*
                    </span>
                  </ng-container>
                </td>
              }
              <td>{{ order.totalArea | number }}м<sup>2</sup></td>
              <!-- <td>
                @if(!order.responsible){ ---- }
                @else {
                  <p style="text-decoration: underline; cursor: help;" title='{{order.responsiblePhone}}' > {{ order.responsible }} </p>
                }
              </td> -->
              <td>
                <ng-container *ngIf="order.responsible; else noResponsible">
                    <p style="text-decoration: underline;"
                       matTooltip="{{ order.responsiblePhone }}"
                       matTooltipPosition="above">
                        {{ order.responsible }}
                    </p>
                </ng-container>
                <ng-template #noResponsible>
                    ----
                </ng-template>
            </td>

            </tr>
          </tbody>
        </table>
      </div>
      @if(kind === 'facade'){
          <div class="border-radius">
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th (click)="sort('typeF')">
                  Тип
                  <span style="text-wrap: nowrap;">
                    фасада <i class="fa" [ngClass]="{'fa-sort': sortField !== 'typeF', 'fa-sort-up': sortField === 'typeF' && sortOrder === 'asc', 'fa-sort-down': sortField === 'typeF' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('thickness')">
                  <span style="text-wrap: nowrap;">
                    Толщина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'thickness', 'fa-sort-up': sortField === 'thickness' && sortOrder === 'asc', 'fa-sort-down': sortField === 'thickness' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('collect')">
                  <span style="text-wrap: nowrap;">
                    Коллекция <i class="fa" [ngClass]="{'fa-sort': sortField !== 'collect', 'fa-sort-up': sortField === 'collect' && sortOrder === 'asc', 'fa-sort-down': sortField === 'collect' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <!-- <th>Фрезеровка</th>-->
                @if(millingCheck){
                  <th (click)="sort('milling')">
                    <span style="text-wrap: nowrap;">
                      Фрезеровка <i class="fa" [ngClass]="{'fa-sort': sortField !== 'milling', 'fa-sort-up': sortField === 'milling' && sortOrder === 'asc', 'fa-sort-down': sortField === 'milling' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
                @if(frezaCheck){
                  <th (click)="sort('freza')">
                    Обкатная
                    <span style="text-wrap: nowrap;">
                      фреза <i class="fa" [ngClass]="{'fa-sort': sortField !== 'freza', 'fa-sort-up': sortField === 'freza' && sortOrder === 'asc', 'fa-sort-down': sortField === 'freza' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
                <th (click)="sort('decor')">
                  <span style="text-wrap: nowrap;">
                    Декор <i class="fa" [ngClass]="{'fa-sort': sortField !== 'decor', 'fa-sort-up': sortField === 'decor' && sortOrder === 'asc', 'fa-sort-down': sortField === 'decor' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(kromkaCheck){
                  <th (click)="sort('kromka')">
                  <span style="text-wrap: nowrap;">
                    Кромка <i class="fa" [ngClass]="{'fa-sort': sortField !== 'kromka', 'fa-sort-up': sortField === 'kromka' && sortOrder === 'asc', 'fa-sort-down': sortField === 'kromka' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(kromkaDesignCheck){
                  <th (click)="sort('kromkaDesign')">
                  Дизайн
                  <span style="text-wrap: nowrap;">
                    кромки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'kromkaDesign', 'fa-sort-up': sortField === 'kromkaDesign' && sortOrder === 'asc', 'fa-sort-down': sortField === 'kromkaDesign' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(textureCheck){
                  <th (click)="sort('texture')">
                  <span style="text-wrap: nowrap;">
                    Текстура <i class="fa" [ngClass]="{'fa-sort': sortField !== 'texture', 'fa-sort-up': sortField === 'texture' && sortOrder === 'asc', 'fa-sort-down': sortField === 'texture' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(starCheck){
                  <th (click)="sort('star')">
                  <span style="text-wrap: nowrap;">
                    Старение <i class="fa" [ngClass]="{'fa-sort': sortField !== 'star', 'fa-sort-up': sortField === 'star' && sortOrder === 'asc', 'fa-sort-down': sortField === 'star' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                @if(polishCheck){
                  <th (click)="sort('polish')">
                  <span style="text-wrap: nowrap;">
                    Лак <i class="fa" [ngClass]="{'fa-sort': sortField !== 'polish', 'fa-sort-up': sortField === 'polish' && sortOrder === 'asc', 'fa-sort-down': sortField === 'polish' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                <th (click)="sort('ispol')">
                  Вид <span style="text-wrap: nowrap;">
                    фасада <i class="fa" [ngClass]="{'fa-sort': sortField !== 'ispol', 'fa-sort-up': sortField === 'ispol' && sortOrder === 'asc', 'fa-sort-down': sortField === 'ispol' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(hingeCheck){
                  <th (click)="sort('hinge')">
                  <span style="text-wrap: nowrap;">
                    Присадки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'hinge', 'fa-sort-up': sortField === 'hinge' && sortOrder === 'asc', 'fa-sort-down': sortField === 'hinge' && sortOrder === 'desc'}"></i>
                  </span>
                  </th>
                }
                <th (click)="sort('height')">
                  <span style="text-wrap: nowrap;">
                    Высота <i class="fa" [ngClass]="{'fa-sort': sortField !== 'height', 'fa-sort-up': sortField === 'height' && sortOrder === 'asc', 'fa-sort-down': sortField === 'height' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('width')">
                  <span style="text-wrap: nowrap;">
                    Ширина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'width', 'fa-sort-up': sortField === 'width' && sortOrder === 'asc', 'fa-sort-down': sortField === 'width' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('amount')">
                  <span style="text-wrap: nowrap;">
                    Кол-во <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amount', 'fa-sort-up': sortField === 'amount' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amount' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('price')">
                  Цена <span style="text-wrap: nowrap;">
                    РРЦ <i class="fa" [ngClass]="{'fa-sort': sortField !== 'price', 'fa-sort-up': sortField === 'price' && sortOrder === 'asc', 'fa-sort-down': sortField === 'price' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('priceCalc')">
                  Сумма <span style="text-wrap: nowrap;">
                    РРЦ <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceCalc', 'fa-sort-up': sortField === 'priceCalc' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceCalc' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(statusCheck && markupCheck) {
                  <th (click)="sort('priceMarkup')">
                    Сумма РРЦ
                    <span style="text-wrap: nowrap;">
                      с % <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceMarkup', 'fa-sort-up': sortField === 'priceMarkup' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceMarkup' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
                <th (click)="sort('area')">
                  <span style="text-wrap: nowrap;">
                    S<sub>детали</sub> <i class="fa" [ngClass]="{'fa-sort': sortField !== 'area', 'fa-sort-up': sortField === 'area' && sortOrder === 'asc', 'fa-sort-down': sortField === 'area' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('totalArea')">
                  <span style="text-wrap: nowrap;">
                    S<sub>общая</sub> <i class="fa" [ngClass]="{'fa-sort': sortField !== 'totalArea', 'fa-sort-up': sortField === 'totalArea' && sortOrder === 'asc', 'fa-sort-down': sortField === 'totalArea' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (position of order.order; track $index) {
              <tr>
                <td>
                  <span style="text-wrap: nowrap;">
                    <button class="empty-button" (click)="moveSuborder($index, 'up')" [disabled]="$index === 0">
                      <i class="fa fa-sort-up"></i>
                    </button>
                    {{ $index+1}}
                    <button class="empty-button" (click)="moveSuborder($index, 'down')" [disabled]="$index === length- 1">
                      <i class="fa fa-sort-down"></i>
                    </button>
                  </span>
                </td>
                <td> {{ position.typeF }} </td>
                <td> {{ position.thickness }} </td>
                <td> {{ position.collect }} </td>
                @if(millingCheck){
                  <td>
                  @if(position.milling){
                    {{ position.milling }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(frezaCheck){
                  <td>
                  @if(position.freza){
                    {{ position.freza }}
                  }
                  @else { ---- }
                  </td>
                }
                <td> {{ position.decor }} </td>
                @if(kromkaCheck){
                  <td>
                  @if(position.kromka){
                    {{ MAP_KROMKA[position.kromka] }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(kromkaDesignCheck){
                  <td>
                  @if(position.kromkaDesign){
                    {{ position.kromkaDesign }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(textureCheck){
                  <td>
                  @if(position.texture){
                    {{ MAP_TEXTURE[position.texture] }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(starCheck){
                  <td>
                  @if(position.star){
                    {{ MAP_STAR[position.star] }}
                  }
                  @else { ---- }
                  </td>
                }
                @if(polishCheck){
                  <td>
                  @if(position.polish){
                    {{ position.polish }}
                  }
                  @else { ---- }
                  </td>
                }
                <td>
                  @if(position.ispol){
                    {{ position.ispol }}
                  }
                  @else { ---- }
                </td>
                @if(hingeCheck){
                  <td>
                  @if(position.hinge){
                    {{ position.hinge }}
                  }
                  @else { ---- }
                  </td>
                }
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.height | number }} 
                  }
                </td>
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.width | number }} 
                  }
                </td>
                <td> 
                  {{ position.amount  }} 
                  @if(position.typeF === 'LUX кромка'){
                     м.п.
                  }
                  </td>
                <td> <span style="text-wrap: nowrap;">{{ position.price | number }} </span></td>
                <td> <span style="text-wrap: nowrap;"> {{ position.priceCalc | number }} </span> </td>
                @if(statusCheck && markupCheck){
                  <td> <span style="text-wrap: nowrap;"> {{ position.priceMarkup  | number}} </span></td>
                }
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.area | number }}м<sup>2</sup>
                  }
                </td>
                <td>
                  @if(position.typeF === 'LUX кромка'){
                    ----
                  }@else {
                    {{ position.totalArea | number }}м<sup>2</sup>
                  }
                </td>
              </tr>
              }
              <tr>
                <td>Итого </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                @if(millingCheck){
                  <td></td>
                }
                @if(frezaCheck){
                  <td></td>
                }
                @if(kromkaCheck){
                  <td></td>
                }
                @if(kromkaDesignCheck){
                  <td></td>
                }
                @if(textureCheck){
                  <td></td>
                }
                @if(starCheck){
                  <td></td>
                }
                @if(polishCheck){
                  <td></td>
                }
                @if(hingeCheck){
                  <td>{{hingeAmount}}</td>
                }
                <td></td>
                <td></td>
                <td> {{ totalAmount  }} </td>
                <td></td>
                <td> <span style="text-wrap: nowrap;">{{ order.totalPriceCalc | number }} </span></td>
                @if(statusCheck && markupCheck){
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup  | number}}</span></td>
                }
                <td></td>
                <td>{{ order.totalArea | number }}м<sup>2</sup></td>
              </tr>
            </tbody>
          </table>
        </div>
        }@else if(kind === 'lamella'){
          <div class="border-radius-lamella">
          <table>
            <thead>
              <tr>
                <th>№</th>
                <th (click)="sort('typeL')">
                  Тип
                  <span style="text-wrap: nowrap;">
                    рейки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'typeL', 'fa-sort-up': sortField === 'typeL' && sortOrder === 'asc', 'fa-sort-down': sortField === 'typeL' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <!-- <th (click)="sort('standart')">
                  <span style="text-wrap: nowrap;">
                    Длина реек<i class="fa" [ngClass]="{'fa-sort': sortField !== 'standart', 'fa-sort-up': sortField === 'standart' && sortOrder === 'asc', 'fa-sort-down': sortField === 'standart' && sortOrder === 'desc'}"></i>
                  </span>
                </th> -->
                <th (click)="sort('plug')">
                  <span style="text-wrap: nowrap;">
                    Заглушки <i class="fa" [ngClass]="{'fa-sort': sortField !== 'plug', 'fa-sort-up': sortField === 'plug' && sortOrder === 'asc', 'fa-sort-down': sortField === 'plug' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('decor')">
                  <span style="text-wrap: nowrap;">
                    Декор <i class="fa" [ngClass]="{'fa-sort': sortField !== 'decor', 'fa-sort-up': sortField === 'decor' && sortOrder === 'asc', 'fa-sort-down': sortField === 'decor' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('height')">
                  <span style="text-wrap: nowrap;">
                    Высота <i class="fa" [ngClass]="{'fa-sort': sortField !== 'height', 'fa-sort-up': sortField === 'height' && sortOrder === 'asc', 'fa-sort-down': sortField === 'height' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('width')">
                  <span style="text-wrap: nowrap;">
                    Ширина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'width', 'fa-sort-up': sortField === 'width' && sortOrder === 'asc', 'fa-sort-down': sortField === 'width' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('length')">
                  <span style="text-wrap: nowrap;">
                    Длина <i class="fa" [ngClass]="{'fa-sort': sortField !== 'length', 'fa-sort-up': sortField === 'length' && sortOrder === 'asc', 'fa-sort-down': sortField === 'length' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('amount')">
                  <span style="text-wrap: nowrap;">
                    Кол-во <i class="fa" [ngClass]="{'fa-sort': sortField !== 'amount', 'fa-sort-up': sortField === 'amount' && sortOrder === 'asc', 'fa-sort-down': sortField === 'amount' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('price')">
                  Цена <span style="text-wrap: nowrap;">
                    РРЦ <i class="fa" [ngClass]="{'fa-sort': sortField !== 'price', 'fa-sort-up': sortField === 'price' && sortOrder === 'asc', 'fa-sort-down': sortField === 'price' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                <th (click)="sort('priceCalc')">
                  Сумма <span style="text-wrap: nowrap;">
                    РРЦ <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceCalc', 'fa-sort-up': sortField === 'priceCalc' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceCalc' && sortOrder === 'desc'}"></i>
                  </span>
                </th>
                @if(statusCheck && markupCheck) {
                  <th (click)="sort('priceMarkup')">
                    Сумма РРЦ
                    <span style="text-wrap: nowrap;">
                      с % <i class="fa" [ngClass]="{'fa-sort': sortField !== 'priceMarkup', 'fa-sort-up': sortField === 'priceMarkup' && sortOrder === 'asc', 'fa-sort-down': sortField === 'priceMarkup' && sortOrder === 'desc'}"></i>
                    </span>
                  </th>
                }
              </tr>
            </thead>
            <tbody>
              @for (position of order.orderLamella; track $index) {
              <tr>
                <td>
                  <span style="text-wrap: nowrap;">
                    <button class="empty-button" (click)="moveSuborder($index, 'up')" [disabled]="$index === 0">
                      <i class="fa fa-sort-up"></i>
                    </button>
                    {{ $index+1}}
                    <button class="empty-button" (click)="moveSuborder($index, 'down')" [disabled]="$index === length- 1">
                      <i class="fa fa-sort-down"></i>
                    </button>
                  </span>
                </td>
                <td> {{ MAP_TYPE[position.typeL] }} </td>
                <!-- <td> {{  MAP_STANDART[position.standart] }} </td> -->
                <td> {{ MAP_PLUG[position.plug] }} </td>
                <td> {{ position.decor }} </td>
                <td> {{ position.height | number }} </td>
                <td> {{ position.width | number }} </td>
                <td> {{ position.length | number }} </td>
                <td> {{ position.amount  }} </td>
                <td> <span style="text-wrap: nowrap;">{{ position.price | number }} </span></td>
                <td> <span style="text-wrap: nowrap;"> {{ position.priceCalc | number }} </span> </td>
                @if(statusCheck && markupCheck){
                  <td> <span style="text-wrap: nowrap;"> {{ position.priceMarkup  | number}} </span></td>
                }
              </tr>
              }
              <tr>
                <td>Итого </td>
                <td></td>
                <!-- <td></td> -->
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td> {{ totalAmount  }} </td>
                <td></td>
                <td> <span style="text-wrap: nowrap;">{{ order.totalPriceCalc | number }} </span></td>
                @if(statusCheck && markupCheck){
                  <td> <span style="text-wrap: nowrap;">{{ order.totalPriceMarkup  | number}}</span></td>
                }
              </tr>
            </tbody>
          </table>
        </div>
        }
      <div class="comments">
        <span class="comments">Комментарий для менеджера - {{ !!order.commentsForCompany ? order.commentsForCompany : ''}} </span> <br/>
      </div>
      @if(statusCheck){
        <div class="comments">
          <span class="comments">Комментарий о вашем клиенте - {{ !!order.commentsForClient ? order.commentsForClient : ''}}</span>
        </div>
      }

    }
  </div>
